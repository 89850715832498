import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import { gql } from "@apollo/client";
export var NOTICE_SEND_MAIL = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation($newsId: Int) {\n    systemSendMailNews(newsId: $newsId) {\n      message\n      isSuccess\n      number\n    }\n  }\n"])));
export var GET_NEWS = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query($id: Int) {\n    systemNews(id: $id) {\n      isSuccess\n      message\n      data {\n        _id\n        newsId\n        news_number\n        title\n        text\n        attachment\n        is_show\n        created_at\n      }\n    }\n  }\n"])));
export var GET_NEWS_LIST = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query($page: Int, $pageSize: Int, $search: String) {\n    systemNewsList(page: $page, pageSize: $pageSize, search: $search) {\n      isSuccess\n      message\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        newsId\n        news_number\n        title\n        is_show\n        attachment\n        created_at\n      }\n    }\n  }\n"])));
export var CREATE_NEWS = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($input: NoticeInput) {\n    createNews(input: $input) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var UPDATE_NEWS = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation($input: NoticeInput) {\n    updateNews(input: $input) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var DELETE_NEWS = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation($id: ID) {\n    deleteNews(_id: $id) {\n      isSuccess\n      message\n    }\n  }\n"])));